import { CSSProperties, useState } from 'react';
import { 
  Button, 
  ButtonProps 
} from '@mui/material';
import { 
  AccountFieldsFragment,
  AppFieldsFragment,
  useRemoveAccountAppMutation
} from '../../../__generated__/graphql';

import IframeDialog from "../../../components/iframe-dialog/IframeDialog";


type Props = {
  app: AppFieldsFragment;
  label?: string;
  button?: JSX.Element;
} & ButtonProps;
export default function LaunchAppButton({ app, label, button, ...props }: Props) {
  label = label || 'Launch App';

  button = button || <Button variant="contained" {...props}>{label}</Button>;

  const url = '/apps/proxy/' + app.handle;
  const useDialog = app.config.launch.target === 'dialog';

  if(useDialog) {
    return <OpenInDialog app={app} button={button} url={url} />
  }
  return (
    <>
      <OpenInNewTab app={app} button={button} url={url} />
    </>  
  )
}

type SubProps = {
  app: AppFieldsFragment;
  button: JSX.Element;
  url: string;
}

function OpenInDialog({ app, button, url }: SubProps) {
  const [open, setOpen] = useState(false);
  
  const dimensions = {
    height: app.config.launch.dialog?.height || undefined,
    width: app.config.launch.dialog?.width || undefined
  }

  return (
    <div style={{ display: 'inline-block'}}>
      <div onClick={()=>setOpen(true)}>{button}</div>
      <IframeDialog {...dimensions} open={open} onClose={()=>setOpen(false)} url={url} />
    </div>
  )
}


function OpenInNewTab({ app, button, url }: SubProps) {
  
  return (
    <div style={{ display: 'inline-block'}}>
      <div onClick={()=>{ window.open(url)}}>{button}</div>
    </div>
  )
}