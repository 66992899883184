import React, { createContext, useContext, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import NavbarContent from './NavbarContent';

import {
  useLogoutMutation
} from '../__generated__/graphql'

const context = createContext<{

}>({
})

export function NavbarProvider({ children }: { children: React.ReactNode }) {

  return <context.Provider value={{}}>
    {children}
  </context.Provider>
}

export function useNavbarContext() {
  return useContext(context);
}

function Navbar() {
  return (
    <AppBar position="static">
      <NavbarContent />
    </AppBar>
  );
}
export default Navbar;

