import {
  CircularProgress
} from '@mui/material'

export default function Loading() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ textAlign: 'center' }}>
        Loading<br />
        <CircularProgress />
      </div>
    </div>
  )
}