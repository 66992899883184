import React, { createContext, useContext } from 'react';
import { UserFieldsFragment, useWhoamiQuery } from '../../__generated__/graphql';
import { useLocalStorage } from 'usehooks-ts'

type UserContext = {
  user: UserFieldsFragment | null;
  refetch: ()=>Promise<void>;
  loading: boolean;
  signedIn: boolean;
}

const context = createContext<UserContext>({
  user: null,
  refetch: async ()=>{},
  loading: false,
  signedIn: false
});

export default context;

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [signedIn, setSignedIn] = useLocalStorage('signedIn', false);

  const { loading, data, error, refetch: refetchQuery } = useWhoamiQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if(data.whoami) setSignedIn(true);
      else setSignedIn(false);
    }
  });
  
  if(error) {
    console.error(error);
    debugger;
  }
  
  const user = data?.whoami || null;

  const refetch = async () => {
    await refetchQuery();
  }

  return <context.Provider value={{ user, refetch, loading, signedIn }}>
    {children}
  </context.Provider>
}

export  function useUserContext(): UserContext {
  return useContext(context);
}