import { CSSProperties } from 'react';
import { Container, Paper, PaperProps, Button, ButtonProps } from "@mui/material";
import { useUserContext } from "../../features/accounts/UserContext";
import Apps from "../../features/apps/Apps";
import { Link, LinkProps } from 'react-router-dom';

export default function Home() {

  const { user } = useUserContext();
  const isAdmin = user ? user.roles.findIndex(role=>role.code === 'admin') > -1 : false;

  return (
    <Container>
      { isAdmin ? <AdminTools /> : '' }
      <h2>Apps</h2>
      <Paper variant='outlined' sx={{ padding: 2 }}>
        <Apps />
      </Paper>
    </Container>
  )
}

function AdminTools(props: PaperProps) {
  return (
    <>
      <h2>Admin Tools</h2>
      <Paper variant='outlined' sx={{ padding: 2 }} {...props}>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10 }}>
          <AdminButton LinkComponent={Link} to="/admin/accounts">
            Accounts
          </AdminButton>
          <AdminButton LinkComponent={Link} to="/admin/apps" >
            Apps
          </AdminButton>
        </div>
      </Paper>
    </>
  )
}

function AdminButton(props: ButtonProps & Partial<LinkProps> ) {
  return <Button color="primary" variant="outlined" sx={{ height: 100, width: 100 }} {...props} >{props.children}</Button>
}