import { PropsWithChildren } from "react";

import { useResizeDetector } from 'react-resize-detector';

type Props = {
  disabled?: boolean;
  children: (maxHeight: number | 'unset' | undefined)=>JSX.Element;
}

export default function TableAutoHeight({ disabled, children: childrenFn }: Props) {
  const { ref, height } = useResizeDetector({ handleWidth: false, handleHeight: true });
  const children = childrenFn(disabled ? 'unset' : height)
  return (
    <div ref={ref} style={{ flexGrow: 1, position: 'relative', height: disabled ? 'unset' : '100%', overflow: 'hidden' }}>
      <div style={{ position: disabled ? 'relative': 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
        { children }
      </div>
    </div>
  )
}