
import { PropsWithChildren, useRef, useState } from 'react';
import { 
  useUpdateUserMutation,
  UserFieldsFragment,
  UpdateUserInput
} from '../../../__generated__/graphql';

import { IconButton, Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogProps, TextField, FormControl, FormControlLabel, Switch } from '@mui/material';
import { Key } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab';

type Props = {
  user: UserFieldsFragment;
  onDone?: (user: UserFieldsFragment)=>void;
}

export default function ChangeUserPasswordButton({ user, onDone }: Props) {

  const [openDialog, setOpenDialog] = useState(false);
  const [updateUser, { loading }] = useUpdateUserMutation();
  const [password, setPassword] = useState('');
  const [type, setType] = useState<'password' | 'text'>('password')

  const handleUpdatePassword = async () => {
    const { data } = await updateUser({ variables: { user: user.uuid, values: { password } } });
    if(!data?.updateUser) throw new Error('could not update user password');
    
    setOpenDialog(false);    
    if(onDone) onDone(data.updateUser);
  }

  const toggleType = () => setType(type === 'password' ? 'text' : 'password')

  return (
    <>
      <IconButton onClick={()=>setOpenDialog(true)}><Key /></IconButton>
      <Dialog open={openDialog} onClose={()=>setOpenDialog(false)}>
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <TextField value={password} onChange={e=>setPassword(e.target.value)} label="Password" InputLabelProps={{ shrink: true }} type={type} />
            <FormControl>
              <FormControlLabel control={<Switch onChange={()=>toggleType()} />} label={ type === 'password' ? 'Show Password' : 'Hide Password'} />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={loading} variant="contained" fullWidth onClick={handleUpdatePassword}>Update Password</LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}