
import SignIn from '../../features/accounts/sign-in/SignIn';

import './Landing.scss';

import { useEffect } from 'react';
import { useUserContext } from "../../features/accounts/UserContext";
import { useNavigate } from 'react-router-dom';

export default function Landing() {
  const { user } = useUserContext();
  const navigate = useNavigate();
  
  useEffect(()=>{
    if(user) navigate('/home')
  }, [user]);


  if(user) return <></>;

  return (
    <div className="Landing">
      <header className="Landing-header">
        
      </header>
      <SignIn />
    </div>
  )
}
