import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button, ButtonProps,
  Dialog, DialogContent, DialogProps, 
} from '@mui/material';

import {
  AccountFieldsFragment,
  useDeleteAccountMutation
} from '../../../__generated__/graphql';

type Props = {
  onDone?: ()=>void;
  account: AccountFieldsFragment;
} & ButtonProps;

export default function DeleteAccountButton({ account, onDone, ...props }: Props) {
  const [deleteAccount, { loading }] = useDeleteAccountMutation();

  const [openDialog, setOpenDialog] = useState(false);

  const handleClick = async () => {
    await deleteAccount({
      variables: {
        uuid: account.uuid
      }
    });
    if(onDone) onDone();
  }

  return (
    <>
      <Button onClick={()=>setOpenDialog(true)} variant="contained" {...props}>Delete Account</Button>
      <Dialog open={openDialog} onClose={()=>setOpenDialog(false)}>
        <DialogContent>
          Delete {account.name}?<br />
          <LoadingButton fullWidth variant="contained" loading={loading} onClick={handleClick}>Confirm</LoadingButton>
        </DialogContent>
      </Dialog>
    </>
  )
}