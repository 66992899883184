import './App.scss';

import { ApolloProvider } from '@apollo/client';

import { UserProvider } from './features/accounts/UserContext';
import { NavbarProvider } from './navbar/Navbar';

import { client as apolloClient } from './apollo';

import RouterSwitch from './routes/RouterSwitch';
import Navbar, { useNavbarContext } from './navbar/Navbar';

import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <ApolloProvider client={apolloClient}>
        <UserProvider>
          <NavbarProvider>
            <AppLayout />
          </NavbarProvider>
        </UserProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
}

function AppLayout() {
  return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <div style={{ flexGrow: 0 }}><Navbar /></div>
    <div style={{ flexGrow: 1 }}><RouterSwitch /></div>
  </div>
}

export default App;
