import lodash from 'lodash';
import { useState, useEffect } from 'react';
import { TextField, Dialog, DialogProps, DialogContent, DialogActions, Switch, Button } from "@mui/material";

import { AccountFieldsFragment, CreateAccountInput, useCreateAccountMutation } from '../../../__generated__/graphql';
import { LoadingButton } from '@mui/lab';

// type Input = {
//   name: string;
//   code: string;
// }
export type { CreateAccountInput };

type PartialInput = Partial<
  Omit<CreateAccountInput, 'primaryUser'> & 
  { primaryUser: Partial<CreateAccountInput['primaryUser']>}
>;

type Props = {
  value?: CreateAccountInput
  onChange?: (value: CreateAccountInput)=>void;
}

const DEFAULT_INPUT = {
  name: '',
  code: '',
  primaryUser: {
    name: '',
    email: '',
    username: '',
    password: ''
  }
}

export default function CreateAccount({ value, onChange }: Props) {
  const [input, setInput] = useState<CreateAccountInput>(DEFAULT_INPUT);
  const [showPassword, setShowPassword] = useState(false);
  
  const updateInput = (newInput: PartialInput) => {
    setInput(lodash.merge({}, input, newInput))
  }

  useEffect(()=>{
    if(onChange) onChange(input);
  }, [onChange, input])

  useEffect(()=>{
    if(value && input !== value) setInput(value);
  }, [value]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <h4>Account:</h4>
      <TextField value={input.name} onChange={e=>updateInput({ name: e.target.value })} autoFocus variant="outlined" label="Name" InputLabelProps={{ shrink: true }}/>
      <TextField value={input.code} onChange={e=>updateInput({ code: e.target.value })} variant="outlined" label="Code" InputLabelProps={{ shrink: true }}/>
      <h4>Primary User:</h4>
      <TextField value={input.primaryUser.name} onChange={e=>updateInput({ primaryUser: { name: e.target.value }})} variant="outlined" label="Name" InputLabelProps={{ shrink: true }}/>
      <TextField value={input.primaryUser.email} onChange={e=>updateInput({ primaryUser: { email: e.target.value } })} variant="outlined" label="Email" InputLabelProps={{ shrink: true }}/>
      <TextField value={input.primaryUser.username} onChange={e=>updateInput({ primaryUser: { username: e.target.value } })} variant="outlined" label="Username" InputLabelProps={{ shrink: true }}/>
      <TextField 
        value={input.primaryUser.password} 
        onChange={e=>updateInput({ primaryUser: { password: e.target.value } })} 
        type={ showPassword ? 'text' : 'password'} variant="outlined" label="Password" 
        InputLabelProps={{ 
          shrink: true,
        }}
        InputProps={{
          endAdornment: <Switch checked={showPassword} onChange={e=>setShowPassword(!showPassword)} />
        }}
      />
    </div>
  )
}

type CreateAccountDialogProps = {
  onCreate?: (account: AccountFieldsFragment)=>void;
} & DialogProps;
export function CreateAccountDialog({ onCreate, ...props}: CreateAccountDialogProps) {
  const [processing, setProcessing] = useState(false);
  const [value, setValue] = useState<CreateAccountInput>(DEFAULT_INPUT);
  const [createAccount] = useCreateAccountMutation();

  const handleCreateAccount = async () => {
    setProcessing(true);
    try {
      const { data } = await createAccount({
        variables: {
          account: value
        }
      });

      if(data?.createAccount && onCreate) {
        onCreate(data.createAccount);
      }

    } catch (error) {
      console.error(error);
    } finally {
      setProcessing(false);
    }
  }
  return (
    <Dialog {...props}>
      <DialogContent>
        <CreateAccount value={value} onChange={(value)=>setValue(value)}/>
      </DialogContent>
      <DialogActions>

        <LoadingButton onClick={handleCreateAccount} loading={processing} fullWidth variant="contained">Create Account</LoadingButton>
      </DialogActions>
    </Dialog>
  )
}