import { useRef, useState } from 'react';
import { 
  useGetAccountAppsQuery,
  AccountFieldsFragment,
  AppFieldsFragment,
  useAddAccountAppMutation,
  useGetAllAppsQuery
} from '../../../__generated__/graphql';

import { 
  Button, ButtonProps, Dialog, DialogActions, DialogContent,
  Table, TableBody, TableRow, TableCell, Checkbox
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

type Props = {
  account: AccountFieldsFragment;
  onDone?: (apps: AppFieldsFragment[])=>void;
} & ButtonProps
export default function AddAccountAppButton({ account, onDone, ...props }: Props) {
  const { data } = useGetAllAppsQuery();
  const apps = data?.getAllApps || [];
  const [selectedApps, setSelectedApps] = useState<{[key: string]: AppFieldsFragment}>({});
  const [processing, setProcessing] = useState(false);

  const [addAccountApp] = useAddAccountAppMutation();
  const [openDialog, setOpenDialog] = useState(false);

  const toggleSelectApp = (app: AppFieldsFragment) => {
    let _selectedApps = Object.assign({}, selectedApps);
    if(_selectedApps[app.uuid]) {
      delete _selectedApps[app.uuid];  
    } 
    else {
      _selectedApps[app.uuid] = app;
    }
    
    setSelectedApps(_selectedApps);
  }

  const handleAddApplications = async () => {
    const apps = Object.values(selectedApps);
    setProcessing(true);
    const promises = apps.map(async app=>{
      const res = await addAccountApp({
        variables: {
          account: account.uuid,
          app: app.uuid
        }
      })
      if(res.data?.addAccountApp === false) {
        throw new Error('could not add app');
        return;
      }
    });

    await Promise.all(promises).finally(()=>{
      setProcessing(false);
      setOpenDialog(false);
      setSelectedApps({});
      if(onDone) onDone(apps);
    });

  }
  
  return (
    <>
      <Button variant="contained" onClick={()=>setOpenDialog(true)} {...props}>Add Application</Button>
      <Dialog open={openDialog} onClose={()=>setOpenDialog(false)}>
        <DialogContent>
        <Table>
          <TableBody>
            {
              apps.map(app=>{
                return (
                  <TableRow key={app.uuid} onClick={()=>toggleSelectApp(app)}>
                    <TableCell>
                      <Checkbox checked={selectedApps[app.uuid] ? true : false} />
                    </TableCell>
                    <TableCell 
                      sx={{ cursor: 'pointer' }}
                      >{app.name}</TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
        </DialogContent>
        <DialogActions>
          <LoadingButton  
            onClick={()=>handleAddApplications()}
            loading={processing}
            variant="contained" fullWidth disabled={Object.keys(selectedApps).length === 0}>Add Selected</LoadingButton>
        </DialogActions>
      </Dialog>
      {/* <SelectAppListDialog 
        open={openAddAppDialog} 
        onClose={()=>setOpenAddAppDialog(false)} 
        onSelect={handleAppSelection}/> */}
    </>
  )
}