import { useState } from 'react';

import {
  useCreateAppMutation,
  CreateAppValues,
  AppFieldsFragment
} from '../../../__generated__/graphql';

import { 
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Switch,
  FormControlLabel,
  FormControl,
  ButtonProps,
  
} from '@mui/material'
import { LoadingButton } from '@mui/lab';
import lodash from 'lodash';
type DeepPartial<T> = T extends object ? {
  [P in keyof T]?: DeepPartial<T[P]>;
} : T;

const DEFAULT_STATE: CreateAppValues = {
  name: '',
  handle: '',
  service: {
    name: '',
    path: '/'
  },
  config: {
    launch: {
      target: 'new_window'
    }
  }
}

type Props = {
  onDone?: (app: AppFieldsFragment)=>void;
} & ButtonProps
export default function CreateAppButton({ onDone, ...props }: Props) {
  const [openDialog, setOpenDialog] = useState(false);
  const [state, setState] = useState<CreateAppValues>(DEFAULT_STATE);
  const [createApp, { loading }] = useCreateAppMutation();

  const updateState = (values: DeepPartial<CreateAppValues>) => {
    setState(lodash.merge({}, state, values));
  }

  const handleCreateApp = async () => {
    const values = Object.assign(state);
    if(state.config.launch.target !== 'dialog') delete values.config.launch.dialog;

    const { data, errors } = await createApp({
      variables: { values }
    })

    if(errors) throw errors;
    if(!data?.createApp) {
      throw new Error('could not create app')
    }
    setOpenDialog(false);
    setState(DEFAULT_STATE);
    if(onDone) onDone(data.createApp);
  }

  const openInDialog = state.config.launch.target === 'dialog';

  return (<>
    <Button variant="contained" onClick={()=>setOpenDialog(true)} {...props}>Create App</Button>
    <Dialog open={openDialog} onClose={()=>setOpenDialog(false)}>
      <DialogContent>
        <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
          <TextField required value={state.name} onChange={(e)=>updateState({ name: e.target.value })} label="App Name" InputLabelProps={{ shrink: true }} />
          <TextField required value={state.handle} onChange={(e)=>updateState({ handle: e.target.value })} label="App Handle" InputLabelProps={{ shrink: true }} />
          <TextField required value={state.service.name} onChange={(e)=>updateState({ service: { name: e.target.value }})} label="Service Name" InputLabelProps={{ shrink: true }} />
          <TextField required value={state.service.path} onChange={(e)=>updateState({ service: { path: e.target.value }})} label="Service Path" InputLabelProps={{ shrink: true }} />
          
          <FormControl >
            <FormControlLabel 
              control={<Switch value={openInDialog} onChange={()=>updateState({ config: { launch: { target: openInDialog ? 'new_window' : 'dialog' }}})} />}
              label={"Open in " + (openInDialog ? 'dialog' : 'new window') }
            />
          </FormControl>

          <TextField disabled={!openInDialog} type="number" value={state.config.launch.dialog?.height || ''} onChange={(e)=>updateState({ config: { launch: { dialog: { height: parseInt(e.target.value) } }}})} label="Dialog Height" InputLabelProps={{ shrink: true }} />
          <TextField disabled={!openInDialog} type="number" value={state.config.launch.dialog?.width || ''} onChange={(e)=>updateState({ config: { launch: { dialog: { width: parseInt(e.target.value) } }}})} label="Dialog Width" InputLabelProps={{ shrink: true }} />
                    
        </div>
      </DialogContent>
      <DialogActions>
        <LoadingButton fullWidth variant="contained" loading={loading} onClick={handleCreateApp}>Create App</LoadingButton>
      </DialogActions>
    </Dialog>
  </>)
}