import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

import ResizeObserver, { useResizeDetector } from 'react-resize-detector';
import TableAutoHeight from '../table-auto-height/TableAutoHeight';

type RowType = {[key: string]: any};

type Props<T> = {
  autoHeight?: boolean;
  showControls?: boolean;
  dense?: boolean;
  rows?: T[];
  page?: number;
  rowsPerPage?: number;
  cols?: string[];
  headers?: string[];
  selected?: T | null;
  onSelect?: (e: React.MouseEvent, row: T) => void;
}

export default function PaginationTable<T>(props: Props<T>) {

  const showControls = props.showControls === undefined ? true : props.showControls;

  const { ref, height, width } = useResizeDetector({ handleWidth: true, handleHeight: true });
  
  const page = props.page || 0;
  const rowsPerPage = props.rowsPerPage || 10;
  const rows = (props.rows || []).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  var cols: string[] = [];
  if(props.cols) cols = props.cols;
  else if(props.rows && props.rows[0]) {
    const firstRow = props.rows[0] as any;
    cols = Object.keys(firstRow).filter(key=>key !== '__typename').filter(key=>(typeof firstRow[key] !== 'object'));
  }
  
  const headers = props.headers || cols;
  
  const emptyRows =  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const style = {}
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: props.autoHeight ? '100%' : 'unset' }}> 
      <TableAutoHeight>
        {(maxHeight)=>(
          <TableContainer sx={{ maxHeight: maxHeight }} >
            <Table
              stickyHeader
              // sx={{ minWidth: 750 }}
              aria-labelledby="pagination-table"
              size={props.dense ? 'small' : 'medium'}
            >
              <TableHead>
                <TableRow>                
                  {
                    headers.map((header) => (
                      <TableCell
                        key={header}
                        align={'center'}
                        padding={'normal'}
                      >
                        {header}
                      </TableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  rows.map((row, index) => {
                    const isItemSelected = row === props.selected;
                    
                    return (
                      <TableRow
                        sx={{ cursor: 'pointer' }} 
                        hover
                        onClick={(event)=>{
                          if(props.onSelect) props.onSelect(event, row);
                        }}
                        role="checkbox"
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                      >
                        { 
                          cols.map(col=>{
                            return <TableCell align="center" key={col}>{(row as any)[col]}</TableCell>
                          })
                        }
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (props.dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={cols.length} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}          
      </TableAutoHeight>
       
      <div style={{ flexGrow: 0 }}>
        {
          showControls && 
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={()=>{}}
            onRowsPerPageChange={()=>{}}
          />
        }
      </div>
    </div>
  )
}