import { Dialog, DialogContent, DialogProps, DialogTitle } from '@mui/material';

type Props = {
  url?: string;
  title?: string;
  height?: number;
  width?: number;
}
export default function IframeDialog({ url, title, height, width, ...props}: DialogProps & Props) {

  return (
    <Dialog {...props}>
      {
        title ? <DialogTitle>{title}</DialogTitle> : <></>
      }
      <DialogContent sx={{ height, width, overflow: 'hidden' }}>
        <iframe src={url} style={{ border: 0, height: '100%', width: '100%' }} />
      </DialogContent>
    </Dialog>
  )

}