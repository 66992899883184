
import { PropsWithChildren, useRef, useState } from 'react';
import { 
  AccountFieldsFragment,
  useCreateUserMutation,
  UserFieldsFragment
} from '../../../__generated__/graphql';

import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogProps, TextField } from '@mui/material';

import { LoadingButton } from '@mui/lab';

type Props = {
  account: AccountFieldsFragment;
  onDone?: (user?: UserFieldsFragment)=>void;

} & ButtonProps
type State = {
  name: string;
  username: string;
  password: string;
  email: string;
}

const DEFAULT_STATE = { name: '', username: '', password: '', email: '' };

export default function CreateUserButton({ account, onDone, ...props }: Props) {
  const [openDialog, setOpenDialog] = useState(false);
  const [createUser, { loading }] = useCreateUserMutation();
  const [error, setError] = useState('');

  const [state, setState] = useState<State>(DEFAULT_STATE);
  const updateState = (newState: Partial<State>) => {
    setState(Object.assign({}, state, newState));
  }

  const handleCreateUser = async () => {
    setError('');
    try {
      const res = await createUser({
        variables: {
          account: account.uuid,
          user: state
        }
      })

      setState(DEFAULT_STATE);
      setOpenDialog(false);
      if(onDone) onDone(res.data?.createUser)
    } catch (error: any) {
      setError(error && error.toString ? error.toString() : 'there was an error');
    }
  }

  return <>
    <Button variant="contained" onClick={()=>setOpenDialog(true)} { ...props }>Create User</Button>
    <Dialog open={openDialog} onClose={()=>setOpenDialog(false)}>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <TextField value={state.name} placeholder='Name' onChange={e=>updateState({ name: e.target.value })} />
          <TextField value={state.email} placeholder='Email' onChange={e=>updateState({ email: e.target.value })} />
          <TextField value={state.username} placeholder='Username' onChange={e=>updateState({ username: e.target.value })} />
          <TextField value={state.password} placeholder='Password' onChange={e=>updateState({ password: e.target.value })} />
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ width: '100%' }}>
          <span style={{ color: 'rosybrown', display: error ? 'block' : 'none' }}>{error}</span>
          <LoadingButton loading={loading} fullWidth variant="contained" onClick={handleCreateUser}>Create User</LoadingButton>
        </div>
      </DialogActions>
    </Dialog>
  </>
}