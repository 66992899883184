
import { PropsWithChildren, useRef, useState } from 'react';
import { 
  useUpdateAccountMutation,
  AccountFieldsFragment,
  UpdateAccountInput
} from '../../../__generated__/graphql';

import { IconButton, Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogProps, TextField } from '@mui/material';
import { Edit } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab';

type Props = {
  account: AccountFieldsFragment;
  onDone?: (user: AccountFieldsFragment)=>void;
}

export default function EditAccountButton({ account, onDone }: Props) {

  const defaultValues = () => ({
    name: account.name,
    code: account.code
  })

  const [openDialog, setOpenDialog] = useState(false);
  const [updateAccount, { loading }] = useUpdateAccountMutation();
  const [state, setState] = useState<UpdateAccountInput>(defaultValues());

  const updateState = (newState: Partial<UpdateAccountInput>) => {
    setState(Object.assign({}, state, newState));
  }

  const handleUpdateAccount = async () => {
    const { data } = await updateAccount({ variables: { account: account.uuid, values: state } });
    if(!data?.updateAccount) throw new Error('could not update account');
    
    setOpenDialog(false);
    
    if(onDone) onDone(data.updateAccount);
  }

  return (
    <>
      <Button variant="contained" onClick={()=>setOpenDialog(true)}>Edit Account</Button>
      <Dialog open={openDialog} onClose={()=>setOpenDialog(false)}>
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <TextField value={state.name} onChange={e=>updateState({ name: e.target.value })} label="Name" InputLabelProps={{ shrink: true }} />
            <TextField value={state.code} onChange={e=>updateState({ code: e.target.value })} label="Code" InputLabelProps={{ shrink: true }} />
          </div>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={loading} variant="contained" fullWidth onClick={handleUpdateAccount}>Update Account</LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}