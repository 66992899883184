import { PropsWithChildren, useRef, useState } from 'react';
import { 
  AccountFieldsFragment,
  AppFieldsFragment,
  useRemoveAccountAppMutation
} from '../../../__generated__/graphql';

import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogProps } from '@mui/material';

import { SelectAppListDialog } from '../apps-list/AppsList';
import { LoadingButton } from '@mui/lab';

type Props = {
  account: AccountFieldsFragment;
  app: AppFieldsFragment;
  onRemove?: (app: AppFieldsFragment)=>void;
} & PropsWithChildren
export default function RemoveAccountAppButton({ account, app, onRemove, children }: Props) {
  const [openRemoveAppDialog, setOpenRemoveAppDialog] = useState(false);
  const [removeAccountApp, { loading }] = useRemoveAccountAppMutation();

  const handleRemove = async () => {
    const res = await removeAccountApp({
      variables: {
        account: account.uuid,
        app: app.uuid
      }
    })
    if(res.data?.removeAccountApp === false) {
      throw new Error('could not remove app');
      return;
    }
    if(onRemove) onRemove(app);
  }
  
  return (
    <>
      { 
        children 
        ? <div onClick={()=>setOpenRemoveAppDialog(true)}>{children}</div>
        : <Button variant="contained" onClick={()=>setOpenRemoveAppDialog(true)}>Remove Application</Button> 
      }
      <Dialog open={openRemoveAppDialog} onClose={()=>setOpenRemoveAppDialog(false)}>
        <DialogContent>
          Remove "{app.name}"?
        </DialogContent>
        <DialogActions>
          <LoadingButton variant="contained" fullWidth loading={loading} onClick={handleRemove}>Confirm</LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}