

import { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { 
  useGetAccountAppsQuery,
  AccountFieldsFragment,
  AppFieldsFragment,
  useAddAccountAppMutation
} from '../../../__generated__/graphql';

import {
  Delete
} from '@mui/icons-material'

import { Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import RemoveAccountAppButton from '../remove-account-app/RemoveAccountAppButton';

export default forwardRef(function AccountApps({ account }: { account: AccountFieldsFragment }, ref) {
  const { data, refetch } = useGetAccountAppsQuery({ variables: { account: account.uuid }});
  const apps = data?.getAccountApps || [];

  useImperativeHandle(ref, ()=>({
    refetch() { 
      return refetch();
    }
  }));

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Handle</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          apps.map(app=>{
            return (
              <TableRow key={app.uuid}>
                <TableCell>{app.name}</TableCell>
                <TableCell>{app.handle}</TableCell>
                <TableCell>
                  <RemoveAccountAppButton account={account} app={app} onRemove={()=>refetch()}>
                    <IconButton><Delete /></IconButton>
                  </RemoveAccountAppButton>
                </TableCell>
              </TableRow>
            )
          })
        }
      </TableBody>
    </Table>
  )
})