import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Account = {
  __typename?: 'Account';
  code: Scalars['String'];
  name: Scalars['String'];
  users: Array<User>;
  uuid: Scalars['ID'];
};

export type AccountEdge = {
  __typename?: 'AccountEdge';
  cursor: Scalars['String'];
  node: Account;
};

export type AccountPage = {
  __typename?: 'AccountPage';
  edges: Array<AccountEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type App = {
  __typename?: 'App';
  config: AppConfig;
  handle: Scalars['String'];
  name: Scalars['String'];
  service: AppService;
  uuid: Scalars['ID'];
};

export type AppConfig = {
  __typename?: 'AppConfig';
  launch: AppConfig_Launch;
};

export type AppConfig_Launch = {
  __typename?: 'AppConfig_Launch';
  dialog?: Maybe<AppConfig_LaunchDialog>;
  target?: Maybe<Scalars['String']>;
};

export type AppConfig_LaunchDialog = {
  __typename?: 'AppConfig_LaunchDialog';
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type AppService = {
  __typename?: 'AppService';
  host: Scalars['String'];
  name: Scalars['String'];
  path: Scalars['String'];
  status: AppStatus;
};

export enum AppStatus {
  Healthy = 'HEALTHY',
  Unhealthy = 'UNHEALTHY',
  Unknown = 'UNKNOWN'
}

export type CreateAccountInput = {
  code: Scalars['String'];
  name: Scalars['String'];
  primaryUser: CreatePrimaryUserInput;
};

export type CreateAppValues = {
  config: CreateAppValues_Config;
  handle: Scalars['String'];
  name: Scalars['String'];
  service: CreateAppValues_Service;
};

export type CreateAppValues_Config = {
  launch: CreateAppValues_Config_Launch;
};

export type CreateAppValues_Config_Launch = {
  dialog?: InputMaybe<CreateAppValues_Config_Launch_Dialog>;
  target: Scalars['String'];
};

export type CreateAppValues_Config_Launch_Dialog = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type CreateAppValues_Service = {
  name: Scalars['String'];
  path: Scalars['String'];
};

export type CreatePrimaryUserInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type CreateUserInput = {
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addAccountApp: Scalars['Boolean'];
  createAccount: Account;
  createApp: App;
  createUser: User;
  deleteAccount: Scalars['Boolean'];
  deleteApp: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  login: User;
  logout: Scalars['Boolean'];
  removeAccountApp: Scalars['Boolean'];
  updateAccount: Account;
  updateApp: App;
  updateUser: User;
};


export type MutationAddAccountAppArgs = {
  account: Scalars['String'];
  app: Scalars['String'];
};


export type MutationCreateAccountArgs = {
  account: CreateAccountInput;
};


export type MutationCreateAppArgs = {
  values: CreateAppValues;
};


export type MutationCreateUserArgs = {
  account: Scalars['String'];
  user: CreateUserInput;
};


export type MutationDeleteAccountArgs = {
  uuid: Scalars['String'];
};


export type MutationDeleteAppArgs = {
  app: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  user: Scalars['String'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationRemoveAccountAppArgs = {
  account: Scalars['String'];
  app: Scalars['String'];
};


export type MutationUpdateAccountArgs = {
  account: Scalars['String'];
  values: UpdateAccountInput;
};


export type MutationUpdateAppArgs = {
  app: Scalars['String'];
  values: UpdateAppValues;
};


export type MutationUpdateUserArgs = {
  user: Scalars['String'];
  values: UpdateUserInput;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['String'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getAccountApps: Array<App>;
  getAccountPage: AccountPage;
  getAllApps: Array<App>;
  getAppAccounts: Array<Account>;
  getOwnAccountApps: Array<App>;
  whoami?: Maybe<User>;
};


export type QueryGetAccountAppsArgs = {
  account: Scalars['String'];
};


export type QueryGetAccountPageArgs = {
  after?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAppAccountsArgs = {
  app: Scalars['String'];
};

export enum Role {
  Admin = 'ADMIN'
}

export type UpdateAccountInput = {
  code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateAppValues = {
  config?: InputMaybe<UpdateAppValues_Config>;
  handle?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<UpdateAppValues_Service>;
};

export type UpdateAppValues_Config = {
  launch?: InputMaybe<UpdateAppValues_Config_Launch>;
};

export type UpdateAppValues_Config_Launch = {
  dialog?: InputMaybe<UpdateAppValues_Config_Launch_Dialog>;
  target?: InputMaybe<Scalars['String']>;
};

export type UpdateAppValues_Config_Launch_Dialog = {
  height?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type UpdateAppValues_Service = {
  name?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  account: Account;
  email: Scalars['String'];
  name: Scalars['String'];
  primary: Scalars['Boolean'];
  roles: Array<UserRole>;
  username: Scalars['String'];
  uuid: Scalars['ID'];
};

export type UserRole = {
  __typename?: 'UserRole';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type AccountFieldsFragment = { __typename?: 'Account', uuid: string, name: string, code: string, users: Array<{ __typename?: 'User', uuid: string, name: string, username: string, email: string, primary: boolean, account: { __typename?: 'Account', uuid: string, code: string, name: string }, roles: Array<{ __typename?: 'UserRole', name: string, code: string }> }> };

export type UserFieldsFragment = { __typename?: 'User', uuid: string, name: string, username: string, email: string, primary: boolean, account: { __typename?: 'Account', uuid: string, code: string, name: string }, roles: Array<{ __typename?: 'UserRole', name: string, code: string }> };

export type CreateAccountMutationVariables = Exact<{
  account: CreateAccountInput;
}>;


export type CreateAccountMutation = { __typename?: 'Mutation', createAccount: { __typename?: 'Account', uuid: string, name: string, code: string, users: Array<{ __typename?: 'User', uuid: string, name: string, username: string, email: string, primary: boolean, account: { __typename?: 'Account', uuid: string, code: string, name: string }, roles: Array<{ __typename?: 'UserRole', name: string, code: string }> }> } };

export type CreateUserMutationVariables = Exact<{
  account: Scalars['String'];
  user: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', uuid: string, name: string, username: string, email: string, primary: boolean, account: { __typename?: 'Account', uuid: string, code: string, name: string }, roles: Array<{ __typename?: 'UserRole', name: string, code: string }> } };

export type DeleteAccountMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type DeleteAccountMutation = { __typename?: 'Mutation', deleteAccount: boolean };

export type DeleteUserMutationVariables = Exact<{
  user: Scalars['String'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'User', uuid: string, name: string, username: string, email: string, primary: boolean, account: { __typename?: 'Account', uuid: string, code: string, name: string }, roles: Array<{ __typename?: 'UserRole', name: string, code: string }> } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type UpdateAccountMutationVariables = Exact<{
  account: Scalars['String'];
  values: UpdateAccountInput;
}>;


export type UpdateAccountMutation = { __typename?: 'Mutation', updateAccount: { __typename?: 'Account', uuid: string, name: string, code: string, users: Array<{ __typename?: 'User', uuid: string, name: string, username: string, email: string, primary: boolean, account: { __typename?: 'Account', uuid: string, code: string, name: string }, roles: Array<{ __typename?: 'UserRole', name: string, code: string }> }> } };

export type UpdateUserMutationVariables = Exact<{
  user: Scalars['String'];
  values: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', uuid: string, name: string, username: string, email: string, primary: boolean, account: { __typename?: 'Account', uuid: string, code: string, name: string }, roles: Array<{ __typename?: 'UserRole', name: string, code: string }> } };

export type GetAccountPageQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetAccountPageQuery = { __typename?: 'Query', getAccountPage: { __typename?: 'AccountPage', totalCount: number, edges: Array<{ __typename?: 'AccountEdge', cursor: string, node: { __typename?: 'Account', uuid: string, name: string, code: string, users: Array<{ __typename?: 'User', uuid: string, name: string, username: string, email: string, primary: boolean, account: { __typename?: 'Account', uuid: string, code: string, name: string }, roles: Array<{ __typename?: 'UserRole', name: string, code: string }> }> } }>, pageInfo: { __typename?: 'PageInfo', startCursor: string, endCursor: string, hasNextPage: boolean, hasPreviousPage: boolean } } };

export type WhoamiQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoamiQuery = { __typename?: 'Query', whoami?: { __typename?: 'User', uuid: string, name: string, username: string, email: string, primary: boolean, account: { __typename?: 'Account', uuid: string, code: string, name: string }, roles: Array<{ __typename?: 'UserRole', name: string, code: string }> } | null };

export type AppFieldsFragment = { __typename?: 'App', uuid: string, name: string, handle: string, config: { __typename?: 'AppConfig', launch: { __typename?: 'AppConfig_Launch', target?: string | null, dialog?: { __typename?: 'AppConfig_LaunchDialog', height?: number | null, width?: number | null } | null } } };

export type AppFields_AdminFragment = { __typename?: 'App', uuid: string, name: string, handle: string, service: { __typename?: 'AppService', name: string, path: string, host: string, status: AppStatus }, config: { __typename?: 'AppConfig', launch: { __typename?: 'AppConfig_Launch', target?: string | null, dialog?: { __typename?: 'AppConfig_LaunchDialog', height?: number | null, width?: number | null } | null } } };

export type AddAccountAppMutationVariables = Exact<{
  account: Scalars['String'];
  app: Scalars['String'];
}>;


export type AddAccountAppMutation = { __typename?: 'Mutation', addAccountApp: boolean };

export type CreateAppMutationVariables = Exact<{
  values: CreateAppValues;
}>;


export type CreateAppMutation = { __typename?: 'Mutation', createApp: { __typename?: 'App', uuid: string, name: string, handle: string, config: { __typename?: 'AppConfig', launch: { __typename?: 'AppConfig_Launch', target?: string | null, dialog?: { __typename?: 'AppConfig_LaunchDialog', height?: number | null, width?: number | null } | null } } } };

export type DeleteAppMutationVariables = Exact<{
  app: Scalars['String'];
}>;


export type DeleteAppMutation = { __typename?: 'Mutation', deleteApp: boolean };

export type RemoveAccountAppMutationVariables = Exact<{
  account: Scalars['String'];
  app: Scalars['String'];
}>;


export type RemoveAccountAppMutation = { __typename?: 'Mutation', removeAccountApp: boolean };

export type UpdateAppMutationVariables = Exact<{
  app: Scalars['String'];
  values: UpdateAppValues;
}>;


export type UpdateAppMutation = { __typename?: 'Mutation', updateApp: { __typename?: 'App', uuid: string, name: string, handle: string, service: { __typename?: 'AppService', name: string, path: string, host: string, status: AppStatus }, config: { __typename?: 'AppConfig', launch: { __typename?: 'AppConfig_Launch', target?: string | null, dialog?: { __typename?: 'AppConfig_LaunchDialog', height?: number | null, width?: number | null } | null } } } };

export type GetAccountAppsQueryVariables = Exact<{
  account: Scalars['String'];
}>;


export type GetAccountAppsQuery = { __typename?: 'Query', getAccountApps: Array<{ __typename?: 'App', uuid: string, name: string, handle: string, config: { __typename?: 'AppConfig', launch: { __typename?: 'AppConfig_Launch', target?: string | null, dialog?: { __typename?: 'AppConfig_LaunchDialog', height?: number | null, width?: number | null } | null } } }> };

export type GetAllAppsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllAppsQuery = { __typename?: 'Query', getAllApps: Array<{ __typename?: 'App', uuid: string, name: string, handle: string, service: { __typename?: 'AppService', name: string, path: string, host: string, status: AppStatus }, config: { __typename?: 'AppConfig', launch: { __typename?: 'AppConfig_Launch', target?: string | null, dialog?: { __typename?: 'AppConfig_LaunchDialog', height?: number | null, width?: number | null } | null } } }> };

export type GetAppAccountsQueryVariables = Exact<{
  app: Scalars['String'];
}>;


export type GetAppAccountsQuery = { __typename?: 'Query', getAppAccounts: Array<{ __typename?: 'Account', uuid: string, name: string, code: string, users: Array<{ __typename?: 'User', uuid: string, name: string, username: string, email: string, primary: boolean, account: { __typename?: 'Account', uuid: string, code: string, name: string }, roles: Array<{ __typename?: 'UserRole', name: string, code: string }> }> }> };

export type GetOwnAccountAppsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOwnAccountAppsQuery = { __typename?: 'Query', getOwnAccountApps: Array<{ __typename?: 'App', uuid: string, name: string, handle: string, config: { __typename?: 'AppConfig', launch: { __typename?: 'AppConfig_Launch', target?: string | null, dialog?: { __typename?: 'AppConfig_LaunchDialog', height?: number | null, width?: number | null } | null } } }> };

export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  uuid
  name
  username
  email
  primary
  account {
    uuid
    code
    name
  }
  roles {
    name
    code
  }
}
    `;
export const AccountFieldsFragmentDoc = gql`
    fragment AccountFields on Account {
  uuid
  name
  code
  users {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export const AppFieldsFragmentDoc = gql`
    fragment AppFields on App {
  uuid
  name
  handle
  config {
    launch {
      target
      dialog {
        height
        width
      }
    }
  }
}
    `;
export const AppFields_AdminFragmentDoc = gql`
    fragment AppFields_Admin on App {
  ...AppFields
  service {
    name
    path
    host
    status
  }
}
    ${AppFieldsFragmentDoc}`;
export const CreateAccountDocument = gql`
    mutation createAccount($account: CreateAccountInput!) {
  createAccount(account: $account) {
    ...AccountFields
  }
}
    ${AccountFieldsFragmentDoc}`;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, options);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($account: String!, $user: CreateUserInput!) {
  createUser(account: $account, user: $user) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      account: // value for 'account'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteAccountDocument = gql`
    mutation deleteAccount($uuid: String!) {
  deleteAccount(uuid: $uuid)
}
    `;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($user: String!) {
  deleteUser(user: $user)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const LoginDocument = gql`
    mutation login($username: String!, $password: String!) {
  login(password: $password, username: $username) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const UpdateAccountDocument = gql`
    mutation updateAccount($account: String!, $values: UpdateAccountInput!) {
  updateAccount(account: $account, values: $values) {
    ...AccountFields
  }
}
    ${AccountFieldsFragmentDoc}`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      account: // value for 'account'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, options);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($user: String!, $values: UpdateUserInput!) {
  updateUser(user: $user, values: $values) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetAccountPageDocument = gql`
    query getAccountPage($after: String, $limit: Int) {
  getAccountPage(after: $after, limit: $limit) {
    totalCount
    edges {
      cursor
      node {
        ...AccountFields
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${AccountFieldsFragmentDoc}`;

/**
 * __useGetAccountPageQuery__
 *
 * To run a query within a React component, call `useGetAccountPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPageQuery({
 *   variables: {
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAccountPageQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountPageQuery, GetAccountPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountPageQuery, GetAccountPageQueryVariables>(GetAccountPageDocument, options);
      }
export function useGetAccountPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountPageQuery, GetAccountPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountPageQuery, GetAccountPageQueryVariables>(GetAccountPageDocument, options);
        }
export type GetAccountPageQueryHookResult = ReturnType<typeof useGetAccountPageQuery>;
export type GetAccountPageLazyQueryHookResult = ReturnType<typeof useGetAccountPageLazyQuery>;
export type GetAccountPageQueryResult = Apollo.QueryResult<GetAccountPageQuery, GetAccountPageQueryVariables>;
export const WhoamiDocument = gql`
    query whoami {
  whoami {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useWhoamiQuery__
 *
 * To run a query within a React component, call `useWhoamiQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoamiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoamiQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoamiQuery(baseOptions?: Apollo.QueryHookOptions<WhoamiQuery, WhoamiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhoamiQuery, WhoamiQueryVariables>(WhoamiDocument, options);
      }
export function useWhoamiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhoamiQuery, WhoamiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhoamiQuery, WhoamiQueryVariables>(WhoamiDocument, options);
        }
export type WhoamiQueryHookResult = ReturnType<typeof useWhoamiQuery>;
export type WhoamiLazyQueryHookResult = ReturnType<typeof useWhoamiLazyQuery>;
export type WhoamiQueryResult = Apollo.QueryResult<WhoamiQuery, WhoamiQueryVariables>;
export const AddAccountAppDocument = gql`
    mutation addAccountApp($account: String!, $app: String!) {
  addAccountApp(account: $account, app: $app)
}
    `;
export type AddAccountAppMutationFn = Apollo.MutationFunction<AddAccountAppMutation, AddAccountAppMutationVariables>;

/**
 * __useAddAccountAppMutation__
 *
 * To run a mutation, you first call `useAddAccountAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAccountAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAccountAppMutation, { data, loading, error }] = useAddAccountAppMutation({
 *   variables: {
 *      account: // value for 'account'
 *      app: // value for 'app'
 *   },
 * });
 */
export function useAddAccountAppMutation(baseOptions?: Apollo.MutationHookOptions<AddAccountAppMutation, AddAccountAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAccountAppMutation, AddAccountAppMutationVariables>(AddAccountAppDocument, options);
      }
export type AddAccountAppMutationHookResult = ReturnType<typeof useAddAccountAppMutation>;
export type AddAccountAppMutationResult = Apollo.MutationResult<AddAccountAppMutation>;
export type AddAccountAppMutationOptions = Apollo.BaseMutationOptions<AddAccountAppMutation, AddAccountAppMutationVariables>;
export const CreateAppDocument = gql`
    mutation createApp($values: CreateAppValues!) {
  createApp(values: $values) {
    ...AppFields
  }
}
    ${AppFieldsFragmentDoc}`;
export type CreateAppMutationFn = Apollo.MutationFunction<CreateAppMutation, CreateAppMutationVariables>;

/**
 * __useCreateAppMutation__
 *
 * To run a mutation, you first call `useCreateAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppMutation, { data, loading, error }] = useCreateAppMutation({
 *   variables: {
 *      values: // value for 'values'
 *   },
 * });
 */
export function useCreateAppMutation(baseOptions?: Apollo.MutationHookOptions<CreateAppMutation, CreateAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAppMutation, CreateAppMutationVariables>(CreateAppDocument, options);
      }
export type CreateAppMutationHookResult = ReturnType<typeof useCreateAppMutation>;
export type CreateAppMutationResult = Apollo.MutationResult<CreateAppMutation>;
export type CreateAppMutationOptions = Apollo.BaseMutationOptions<CreateAppMutation, CreateAppMutationVariables>;
export const DeleteAppDocument = gql`
    mutation deleteApp($app: String!) {
  deleteApp(app: $app)
}
    `;
export type DeleteAppMutationFn = Apollo.MutationFunction<DeleteAppMutation, DeleteAppMutationVariables>;

/**
 * __useDeleteAppMutation__
 *
 * To run a mutation, you first call `useDeleteAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppMutation, { data, loading, error }] = useDeleteAppMutation({
 *   variables: {
 *      app: // value for 'app'
 *   },
 * });
 */
export function useDeleteAppMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAppMutation, DeleteAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAppMutation, DeleteAppMutationVariables>(DeleteAppDocument, options);
      }
export type DeleteAppMutationHookResult = ReturnType<typeof useDeleteAppMutation>;
export type DeleteAppMutationResult = Apollo.MutationResult<DeleteAppMutation>;
export type DeleteAppMutationOptions = Apollo.BaseMutationOptions<DeleteAppMutation, DeleteAppMutationVariables>;
export const RemoveAccountAppDocument = gql`
    mutation removeAccountApp($account: String!, $app: String!) {
  removeAccountApp(account: $account, app: $app)
}
    `;
export type RemoveAccountAppMutationFn = Apollo.MutationFunction<RemoveAccountAppMutation, RemoveAccountAppMutationVariables>;

/**
 * __useRemoveAccountAppMutation__
 *
 * To run a mutation, you first call `useRemoveAccountAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAccountAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAccountAppMutation, { data, loading, error }] = useRemoveAccountAppMutation({
 *   variables: {
 *      account: // value for 'account'
 *      app: // value for 'app'
 *   },
 * });
 */
export function useRemoveAccountAppMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAccountAppMutation, RemoveAccountAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAccountAppMutation, RemoveAccountAppMutationVariables>(RemoveAccountAppDocument, options);
      }
export type RemoveAccountAppMutationHookResult = ReturnType<typeof useRemoveAccountAppMutation>;
export type RemoveAccountAppMutationResult = Apollo.MutationResult<RemoveAccountAppMutation>;
export type RemoveAccountAppMutationOptions = Apollo.BaseMutationOptions<RemoveAccountAppMutation, RemoveAccountAppMutationVariables>;
export const UpdateAppDocument = gql`
    mutation updateApp($app: String!, $values: UpdateAppValues!) {
  updateApp(app: $app, values: $values) {
    ...AppFields_Admin
  }
}
    ${AppFields_AdminFragmentDoc}`;
export type UpdateAppMutationFn = Apollo.MutationFunction<UpdateAppMutation, UpdateAppMutationVariables>;

/**
 * __useUpdateAppMutation__
 *
 * To run a mutation, you first call `useUpdateAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppMutation, { data, loading, error }] = useUpdateAppMutation({
 *   variables: {
 *      app: // value for 'app'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUpdateAppMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppMutation, UpdateAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppMutation, UpdateAppMutationVariables>(UpdateAppDocument, options);
      }
export type UpdateAppMutationHookResult = ReturnType<typeof useUpdateAppMutation>;
export type UpdateAppMutationResult = Apollo.MutationResult<UpdateAppMutation>;
export type UpdateAppMutationOptions = Apollo.BaseMutationOptions<UpdateAppMutation, UpdateAppMutationVariables>;
export const GetAccountAppsDocument = gql`
    query getAccountApps($account: String!) {
  getAccountApps(account: $account) {
    ...AppFields
  }
}
    ${AppFieldsFragmentDoc}`;

/**
 * __useGetAccountAppsQuery__
 *
 * To run a query within a React component, call `useGetAccountAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountAppsQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useGetAccountAppsQuery(baseOptions: Apollo.QueryHookOptions<GetAccountAppsQuery, GetAccountAppsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountAppsQuery, GetAccountAppsQueryVariables>(GetAccountAppsDocument, options);
      }
export function useGetAccountAppsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountAppsQuery, GetAccountAppsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountAppsQuery, GetAccountAppsQueryVariables>(GetAccountAppsDocument, options);
        }
export type GetAccountAppsQueryHookResult = ReturnType<typeof useGetAccountAppsQuery>;
export type GetAccountAppsLazyQueryHookResult = ReturnType<typeof useGetAccountAppsLazyQuery>;
export type GetAccountAppsQueryResult = Apollo.QueryResult<GetAccountAppsQuery, GetAccountAppsQueryVariables>;
export const GetAllAppsDocument = gql`
    query getAllApps {
  getAllApps {
    ...AppFields_Admin
  }
}
    ${AppFields_AdminFragmentDoc}`;

/**
 * __useGetAllAppsQuery__
 *
 * To run a query within a React component, call `useGetAllAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAppsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAppsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAppsQuery, GetAllAppsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAppsQuery, GetAllAppsQueryVariables>(GetAllAppsDocument, options);
      }
export function useGetAllAppsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAppsQuery, GetAllAppsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAppsQuery, GetAllAppsQueryVariables>(GetAllAppsDocument, options);
        }
export type GetAllAppsQueryHookResult = ReturnType<typeof useGetAllAppsQuery>;
export type GetAllAppsLazyQueryHookResult = ReturnType<typeof useGetAllAppsLazyQuery>;
export type GetAllAppsQueryResult = Apollo.QueryResult<GetAllAppsQuery, GetAllAppsQueryVariables>;
export const GetAppAccountsDocument = gql`
    query getAppAccounts($app: String!) {
  getAppAccounts(app: $app) {
    ...AccountFields
  }
}
    ${AccountFieldsFragmentDoc}`;

/**
 * __useGetAppAccountsQuery__
 *
 * To run a query within a React component, call `useGetAppAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppAccountsQuery({
 *   variables: {
 *      app: // value for 'app'
 *   },
 * });
 */
export function useGetAppAccountsQuery(baseOptions: Apollo.QueryHookOptions<GetAppAccountsQuery, GetAppAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppAccountsQuery, GetAppAccountsQueryVariables>(GetAppAccountsDocument, options);
      }
export function useGetAppAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppAccountsQuery, GetAppAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppAccountsQuery, GetAppAccountsQueryVariables>(GetAppAccountsDocument, options);
        }
export type GetAppAccountsQueryHookResult = ReturnType<typeof useGetAppAccountsQuery>;
export type GetAppAccountsLazyQueryHookResult = ReturnType<typeof useGetAppAccountsLazyQuery>;
export type GetAppAccountsQueryResult = Apollo.QueryResult<GetAppAccountsQuery, GetAppAccountsQueryVariables>;
export const GetOwnAccountAppsDocument = gql`
    query getOwnAccountApps {
  getOwnAccountApps {
    ...AppFields
  }
}
    ${AppFieldsFragmentDoc}`;

/**
 * __useGetOwnAccountAppsQuery__
 *
 * To run a query within a React component, call `useGetOwnAccountAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOwnAccountAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOwnAccountAppsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOwnAccountAppsQuery(baseOptions?: Apollo.QueryHookOptions<GetOwnAccountAppsQuery, GetOwnAccountAppsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOwnAccountAppsQuery, GetOwnAccountAppsQueryVariables>(GetOwnAccountAppsDocument, options);
      }
export function useGetOwnAccountAppsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOwnAccountAppsQuery, GetOwnAccountAppsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOwnAccountAppsQuery, GetOwnAccountAppsQueryVariables>(GetOwnAccountAppsDocument, options);
        }
export type GetOwnAccountAppsQueryHookResult = ReturnType<typeof useGetOwnAccountAppsQuery>;
export type GetOwnAccountAppsLazyQueryHookResult = ReturnType<typeof useGetOwnAccountAppsLazyQuery>;
export type GetOwnAccountAppsQueryResult = Apollo.QueryResult<GetOwnAccountAppsQuery, GetOwnAccountAppsQueryVariables>;