
import { useState } from 'react';

import {
  useDeleteAppMutation,
  AppFields_AdminFragment as App
} from '../../../__generated__/graphql';

import { 
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Switch,
  FormControlLabel,
  FormControl,
  ButtonProps,
  
} from '@mui/material'
import { LoadingButton } from '@mui/lab';

type Props = {
  app: App;
  onDone?: ()=>void;
}

export default function DeleteAppButton({ app, onDone }: Props) {
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteApp, { loading }] = useDeleteAppMutation();

  const handleDeleteApp = async () => {
    const { data } = await deleteApp({ variables: { app: app.uuid } });
    if(!data?.deleteApp) {
      throw new Error('could not delete app');
    }

    if(onDone) onDone();
  }

  return (
    <>
      <Button variant="contained" onClick={()=>setOpenDialog(true)}>Delete App</Button>
      <Dialog open={openDialog} onClose={()=>setOpenDialog(false)}>
        <DialogContent>
          Delete "{app.name}"?
        </DialogContent>
        <DialogActions>
          <LoadingButton variant="contained" loading={loading} onClick={handleDeleteApp}>Confirm Delete</LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}