import { InMemoryCache } from "@apollo/client";

//@ts-ignore
import * as SortedArray from 'sorted-array';

import { 
 } from '../__generated__/graphql';


function sortedMerge(a: any[], b: any[], comparitor: any) {
  const sortedArray = new SortedArray(a, comparitor);
  for(var i = 0; i < b.length; i++) {
    sortedArray.insert(b[i]);
  }
  return sortedArray.array;
}

function edgeComparitor(field: string = 'createdAt', reverse = false) {
  const direction = reverse ? -1 : 1
  
  return function comparitor(a: { node: any }, b: { node: any }) {
    if(a.node[field] > b.node[field]) return direction;
    return -direction;
  }
}



const cache = new InMemoryCache({
  typePolicies: {

    Query: {
      fields: {
        
      }
    },
  }
});

export default cache;