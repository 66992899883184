import { PropsWithChildren } from 'react';
import { useLocalStorage } from 'usehooks-ts'

import Loading from '../components/loading/Loading';
import Home from './home/Home';
import Landing from './landing/Landing';

import EditAccounts from './admin/Accounts';
import EditApps from './admin/Apps';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  RouteProps,
  Navigate,
  Outlet
} from 'react-router-dom';

import { useUserContext } from '../features/accounts/UserContext';

export default function RouterSwitch() {
  const { user, loading, signedIn } = useUserContext();
  
  if(!user && loading && signedIn) {
    return <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}><Loading /></div>
  }

  return (
      <Routes>

        <Route path="" element={<Landing />} />
        <Route path="landing" element={<Landing />}  />
        <Route path="home" 
          element={<ProtectedRoute user={user}><Home /></ProtectedRoute>}
        />
        <Route path="admin/accounts" 
          element={<ProtectedRoute user={user}><EditAccounts /></ProtectedRoute>}
        />
        <Route path="admin/apps" 
          element={<ProtectedRoute user={user}><EditApps /></ProtectedRoute>}
        />
      </Routes>
  );
}


type ProtectedRouteProps = PropsWithChildren<{
  user: any;
  redirectPath?: string;
}>

function ProtectedRoute(props: ProtectedRouteProps): React.ReactElement {
  const redirectPath = props.redirectPath || '/landing';
  if(!props.user) {
    return <Navigate to={redirectPath} replace />
  }
  if(props.children) {
    return props.children as React.ReactElement;
  }
  return <Outlet />
}

// function ProtectedRoute(props: ProtectedRouteProps) {
//   const { user, redirectPath, component: Component, ...rest } = props;
  
//   return (
//     <Route 
//       {...rest}
//       render={
//         (props) => {

//           if(user) return <Redirect to={redirectPath} />

//           return <Component {...props } />

//         }
//       }
//     />
//   )
// }