
import { Box, SxProps } from '@mui/material';
import { useState, CSSProperties } from "react";
import {
  AppFieldsFragment,
  useGetOwnAccountAppsQuery
} from '../../__generated__/graphql'

import LaunchAppButton from './launch-app/LaunchAppButton';

const BOX_STYLE: SxProps = { 
  height: 100, 
  width: 100, 
  border: 1, 
  display: 'flex', 
  alignItems: 'center', 
  textAlign: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  ":hover": {
    backgroundColor: 'lightgray'
  }
}

const NO_APPS_STYLE: CSSProperties = { 
  height: 100, color: 'grey', display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' 
}

export default function Apps() {
  const { data } = useGetOwnAccountAppsQuery();

  const apps = data?.getOwnAccountApps || [];

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10 }}>
      {
        apps.map(app=><AppBox key={app.uuid} app={app} />)
      }
      {
        apps.length === 0 ? <NoApps /> : ''
      }
      <div style={{ flexGrow: 1 }}></div>
    </div>
  )
}

function NoApps() {
  return (
    <>
      <div style={NO_APPS_STYLE}>No Apps</div>
    </>
  )
}

function AppBox({ app }: { app: AppFieldsFragment }) {

  const button = (
    <Box sx={BOX_STYLE}>
      <span>{app.name}</span>
    </Box>
  )

  return (<>
    <LaunchAppButton button={button} app={app} />
  </>
  )
}