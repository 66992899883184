
import { PropsWithChildren, useRef, useState } from 'react';
import { 
  useDeleteUserMutation,
  UserFieldsFragment,
  
} from '../../../__generated__/graphql';

import { IconButton, Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogProps, TextField } from '@mui/material';
import { Delete } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab';

type Props = {
  user: UserFieldsFragment;
  onDone?: ()=>void;
}

export default function DeleteUserButton({ user, onDone }: Props) {

  const [openDialog, setOpenDialog] = useState(false);
  const [deleteUser, { loading }] = useDeleteUserMutation();
  

  const handleDeleteUser = async () => {
    const { data } = await deleteUser({ variables: { user: user.uuid } });
    if(!data?.deleteUser) throw new Error('could not delete user');
    
    setOpenDialog(false);    
    if(onDone) onDone();
  }

  return (
    <>
      <IconButton onClick={()=>setOpenDialog(true)}><Delete /></IconButton>
      <Dialog open={openDialog} onClose={()=>setOpenDialog(false)}>
        <DialogContent>
          Delete user: "{user.username}"?
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={loading} variant="contained" fullWidth onClick={handleDeleteUser}>Confirm Delete</LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}