
import { useEffect, useRef, useState } from 'react';
import { 
  useGetAccountPageQuery,
  AccountFieldsFragment,
} from '../../__generated__/graphql';

import { CreateAccountDialog } from '../../features/accounts/create-account/CreateAccount';
import { Box, Button, Grid, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import DeleteAccountButton from '../../features/accounts/delete-account/DeleteAccountButton';
import EditAccountButton from '../../features/accounts/edit-account/EditAccountButton';

import AddAccountAppButton from '../../features/apps/add-account-app/AddAccountAppButton';
import AccountApps from '../../features/apps/account-apps/AccountApps';
import CreateUserButton from '../../features/accounts/create-user/CreateUserButton';

import { TabContext, TabList, TabPanel } from '@mui/lab';

import DeleteUserButton from '../../features/accounts/delete-user/DeleteUserButton';
import EditUserButton from '../../features/accounts/edit-user/EditUserButton';
import ChangeUserPasswordButton from '../../features/accounts/edit-user/ChangeUserPasswordButton';

import PaginationTable from '../../components/pagination-table/PaginationTable';
import TableAutoHeight from '../../components/table-auto-height/TableAutoHeight';

export default function AccountsRoute() {
  const [selected, setSelected] = useState<AccountFieldsFragment | null>(null);  
  const [createAccountDialogOpen, setCreateAccountDialogOpen] = useState(false);

  const { data, refetch: refetchAccounts } = useGetAccountPageQuery();

  const accounts = data?.getAccountPage.edges.map(edge=>edge.node) || [];

  const handleOnCreateAccount = (account: AccountFieldsFragment) => {
    setCreateAccountDialogOpen(false);
    refetchAccounts();
  }

  useEffect(()=>{
    if(!selected) return;
    setSelected(accounts.find(account=>account.uuid === selected.uuid) || null);
  }, [accounts])
  

  return (
    <div style={{ height: '100%', padding: 10, boxSizing: 'border-box' }}>
      <Grid container sx={{ height: '100%' }} spacing={2}>
        <Grid item xs={4}>
          <Paper style={{ overflow: 'auto', height: '100%', position: 'relative' }} variant="outlined">
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <h3 style={{ marginLeft: 10 }}>Accounts:</h3>
              <Box sx={{ m: 1, borderColor: 'text.primary', border: 1, flexGrow: 1 }}>
                <PaginationTable 
                  showControls={false}
                  headers={['UUID', 'Name', 'Code']}
                  cols={['uuid', 'name', 'code']}
                  autoHeight
                  rows={accounts} 
                  onSelect={(e, account)=>setSelected(account)}
                  selected={selected}
                />
              </Box>
              <Box sx={{ m: 1 }}>
                <Button variant="contained" onClick={()=>setCreateAccountDialogOpen(true)}>Create Account</Button>
                <CreateAccountDialog 
                  open={createAccountDialogOpen} 
                  onClose={()=>setCreateAccountDialogOpen(false)} 
                  onCreate={handleOnCreateAccount}
                />
              </Box>
            </div>            
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper sx={{ height: '100% '}} style={{ overflow: 'auto' }} variant="outlined">
            { 
              selected 
                ? <SelectedAccount 
                    account={selected} 
                    refetchAccounts={refetchAccounts} 
                  /> 
                : <h4 style={{ marginLeft: 10 }}>Select an account..</h4> 
            }
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}


type SelectedAccountProps = {
  account: AccountFieldsFragment, 
  refetchAccounts: ()=>void
}

function SelectedAccount({ account, refetchAccounts }: SelectedAccountProps) {
  const accountAppsRef = useRef<{ refetch: ()=>void; }>();
  const [tabIndex, setTabIndex] = useState('0');

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <h3 style={{ marginLeft: 10 }}>Selected Account:</h3>
      <Grid container style={{ flexGrow: 1, overflow: 'auto' }}>
        <Grid item xs={4}>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ m: 1, borderColor: 'grey.500', border: 1, flexGrow: 1 }}>
              <AccountDetailsTable account={account} />
            </Box>
            <Box sx={{ m: 1, display: 'flex' }}>     
              <div style={{ flexGrow: 1 }}>
                <DeleteAccountButton account={account} onDone={refetchAccounts} />
              </div>         
              <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
                <EditAccountButton account={account} onDone={refetchAccounts} />
              </div>
            </Box>
          </div>
        </Grid>
        <Grid item xs={8}>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ m: 1, borderColor: 'text.primary', border: 1, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <TabContext value={tabIndex}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={(e, value)=>setTabIndex(value)} aria-label="app details">
                    <Tab label="Apps" value="0" />
                    <Tab label="Users" value="1" />
                  </TabList>
                </Box>
                <TabPanel style={{ flexGrow: 1, padding: 0 }} value="0"><AccountApps account={account} ref={accountAppsRef} /></TabPanel>
                <TabPanel style={{ flexGrow: 1, padding: 0 }} value="1"><AccountUsers account={account} refetch={refetchAccounts}/></TabPanel>
              </TabContext>
            </Box>
            <Box sx={{ m: 1, gap: 1, display: 'flex' }}>
              <AddAccountAppButton account={account} onDone={()=>{ accountAppsRef.current?.refetch() }} />              
              <CreateUserButton account={account} onDone={refetchAccounts}/>
            </Box>
          </div>
        </Grid>
      </Grid> 
    </div>
  )
}

function AccountDetailsTable({ account }: { account: AccountFieldsFragment }) {
  // const { data, loading } = useGetAccountAppsQuery({ variables: { account: account.uuid }});
  // const apps = data?.getAccountApps || [];

  return (
    <TableAutoHeight> 
      {(maxHeight)=>(
        <TableContainer sx={{ maxHeight }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  Account Details
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell width={50}>
                  Name:
                </TableCell>
                <TableCell>
                  { account.name }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Code:
                </TableCell>
                <TableCell>
                  { account.code }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Users:
                </TableCell>
                <TableCell>
                  <div style={{ display: 'flex' }}>
                    { account.users.map(user=>user.username).join(', ') }              
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}        
    </TableAutoHeight>
  )
}

function AccountUsers({ account, refetch }: { account: AccountFieldsFragment, refetch: ()=>void; }) {

  return (
    // <ReactHeight onHeightReady={height=>console.log(height)}>
    
      <TableAutoHeight>
        {
          (maxHeight)=>(
            <TableContainer sx={{ maxHeight }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>Primary</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody >
                  {
                    account.users.map(user=>
                      <TableRow key={user.uuid}>
                        <TableCell>{ user.name }</TableCell>
                        <TableCell>{ user.username }</TableCell>
                        <TableCell>{ user.email }</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>{ user.primary ? 'X' : '' }</TableCell>
                        <TableCell>
                          <EditUserButton user={user} onDone={refetch} />
                          <DeleteUserButton user={user} onDone={refetch} />
                          <ChangeUserPasswordButton user={user} onDone={refetch} />
                          
                        </TableCell>
                      </TableRow>  
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
          )
        }
          
      </TableAutoHeight>
    
  )
}