import { ApolloClient } from "@apollo/client";
import cache from './cache';

// import { createUploadLink } from 'apollo-upload-client';

// import { loader } from 'graphql.macro';

const apolloClient = new ApolloClient({
  uri: window.location.origin + "/graphql",
  cache,
  defaultOptions: {
    query: {
      errorPolicy: 'all'
    }
  },
  // local schema typeDefs
  // typeDefs: loader('../local.schema.graphql'),
  // link: createUploadLink({
  //   uri: window.location.origin + "/graphql"
  // })
});

export default apolloClient;
