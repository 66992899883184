
import { PropsWithChildren, useRef, useState } from 'react';
import { 
  useUpdateUserMutation,
  UserFieldsFragment,
  UpdateUserInput
} from '../../../__generated__/graphql';

import { IconButton, Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogProps, TextField } from '@mui/material';
import { Edit } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab';

type Props = {
  user: UserFieldsFragment;
  onDone?: (user: UserFieldsFragment)=>void;
}

export default function EditUserButton({ user, onDone }: Props) {

  const defaultValues = () => ({
    username: user.username,
    email: user.email,
    name: user.name
  })

  const [openDialog, setOpenDialog] = useState(false);
  const [updateUser, { loading }] = useUpdateUserMutation();
  const [state, setState] = useState<UpdateUserInput>(defaultValues());

  const updateState = (newState: Partial<UpdateUserInput>) => {
    setState(Object.assign({}, state, newState));
  }

  const handleUpdateUser = async () => {
    const { data } = await updateUser({ variables: { user: user.uuid, values: state } });
    if(!data?.updateUser) throw new Error('could not update user');
    
    setOpenDialog(false);
    
    if(onDone) onDone(data.updateUser);
  }

  return (
    <>
      <IconButton onClick={()=>setOpenDialog(true)}><Edit /></IconButton>
      <Dialog open={openDialog} onClose={()=>setOpenDialog(false)}>
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <TextField value={state.name} onChange={e=>updateState({ name: e.target.value })} label="Name" InputLabelProps={{ shrink: true }} />
            <TextField value={state.email} onChange={e=>updateState({ email: e.target.value })} label="Email" InputLabelProps={{ shrink: true }} />
            <TextField value={state.username} onChange={e=>updateState({ username: e.target.value })} label="Username" InputLabelProps={{ shrink: true }} />
          </div>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={loading} variant="contained" fullWidth onClick={handleUpdateUser}>Update User</LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}