


import { useEffect, useState } from 'react';
import { 
  useGetAllAppsQuery,
  AppFields_AdminFragment,
  useGetAppAccountsQuery
} from '../../__generated__/graphql';

import { Box, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Tab, IconButton, TableContainer } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Delete } from '@mui/icons-material';
import RemoveAccountAppButton from '../../features/apps/remove-account-app/RemoveAccountAppButton';
import LaunchAppButton from '../../features/apps/launch-app/LaunchAppButton';
import CreateAppButton from '../../features/apps/create-app/CreateAppButton';
import UpdateAppButton from '../../features/apps/update-app/UpdateAppButton';
import DeleteAppButton from '../../features/apps/delete-app/DeleteAppButton';

import TableAutoHeight from '../../components/table-auto-height/TableAutoHeight';

export default function AppsRoute() {
  const [selected, setSelected] = useState<AppFields_AdminFragment | null>(null)
  const { data, refetch } = useGetAllAppsQuery({
    onCompleted() {
      
    }
  });
  const apps = data?.getAllApps || [];

  useEffect(()=>{
    if(!selected) return;
    setSelected(apps.find(app=>app.uuid === selected.uuid) || null);
  }, [apps]);


  return (
    <div style={{ height: '100%', padding: 10, boxSizing: 'border-box' }}>
      <Grid container sx={{ height: '100%' }} spacing={2}>
        <Grid item xs={4}>
          <Paper sx={{ height: '100%' }} style={{ overflow: 'auto' }} variant="outlined">
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <h3 style={{ marginLeft: 10 }}>Apps:</h3>
              <Box sx={{ m: 1, border: 1, borderColor: 'grey.500', flexGrow: 1, padding: 1 }}>
                <TableAutoHeight>
                  {(maxHeight)=>(
                    <TableContainer sx={{ maxHeight }}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>UUID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Handle</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            apps.map(app=>
                              <TableRow 
                                key={app.uuid} 
                                onClick={()=>setSelected(app)} 
                                sx={{ cursor: 'pointer' }} 
                                hover
                                selected={app.uuid === selected?.uuid}
                              >
                                <TableCell>{app.uuid}</TableCell>
                                <TableCell>{app.name}</TableCell>
                                <TableCell>{app.handle}</TableCell>
                              </TableRow>
                            )
                          }              
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </TableAutoHeight>
                

              </Box>
              <Box sx={{ m: 1, border: 1, borderColor: 'grey.200', flexGrow: 0, padding: 1 }}>
                <CreateAppButton onDone={()=>refetch()} />
              </Box>
              
            </div>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper sx={{ height: '100%' }} style={{ overflow: 'auto' }} variant="outlined">
            {
              selected ? <SelectedApp app={selected} refetchApps={()=>refetch()}/> : ''
            }
          </Paper>
        </Grid>
      </Grid>
    </div>

  )
}


type SelectedAppProps = {
  app: AppFields_AdminFragment;
  refetchApps: ()=>void;
}
function SelectedApp({ app, refetchApps }: SelectedAppProps) {
  const [tabIndex, setTabIndex] = useState('0');

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <h3 style={{ marginLeft: 10 }}>Selected Application:</h3>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Grid container style={{ flexGrow: 1 }}>
          <Grid item xs={4}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Box sx={{ m: 1, border: 1, borderColor: 'grey.500', flexGrow: 1 }}>
                <AppDetailsTable app={app} />
              </Box>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Box sx={{ m: 1, border: 1, borderColor: 'grey.500', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <TabContext value={tabIndex}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(e, value)=>setTabIndex(value)} aria-label="app details">
                      <Tab label="Accounts" value="0" />
                      <Tab label="Config" value="1" />
                    </TabList>
                  </Box>
                  <TabPanel style={{ padding: 0, flexGrow: 1 }} value="0"><AppAccounts app={app} /></TabPanel>
                  <TabPanel style={{ padding: 10, flexGrow: 1 }} value="1"><AppConfig app={app} /></TabPanel>
                </TabContext>
              </Box>
            </div>
          </Grid>
        </Grid> 
        <Box sx={{ m: 1, border: 1, borderColor: 'grey.200', flexGrow: 0, padding: 1, gap: 1, display: 'flex' }}>
          <LaunchAppButton app={app}/>
          <UpdateAppButton app={app} onDone={refetchApps} />
          <DeleteAppButton app={app} onDone={refetchApps} />
        </Box>
      </div>
    </div>
  )
}

type AppDetailsTableProps = {
  app: AppFields_AdminFragment;
}
function AppDetailsTable({ app }: AppDetailsTableProps) {
  return (
    <TableAutoHeight>
      {
        (maxHeight)=>(
          <TableContainer sx={{ maxHeight }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>App Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell width={50}>
                    Name:
                  </TableCell>
                  <TableCell>
                    { app.name }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Handle:
                  </TableCell>
                  <TableCell>
                    { app.handle }
                  </TableCell>
                </TableRow>   
                <TableRow>
                  <TableCell>
                    Service Name:
                  </TableCell>
                  <TableCell>
                    { app.service.name }
                  </TableCell>
                </TableRow>  
                <TableRow>
                  <TableCell>
                    Path:
                  </TableCell>
                  <TableCell>
                    { app.service.path }
                  </TableCell>
                </TableRow> 
                <TableRow>
                  <TableCell>
                    Host:
                  </TableCell>
                  <TableCell>
                    { app.service.host }
                  </TableCell>
                </TableRow> 
                <TableRow>
                  <TableCell>
                    Status:
                  </TableCell>
                  <TableCell>
                    { app.service.status.toLowerCase() }
                  </TableCell>
                </TableRow> 
              </TableBody>
            </Table>
          </TableContainer>
        )
      }
    </TableAutoHeight>
    
  )
}

type AppAccountsProps = {
  app: AppFields_AdminFragment;
}
function AppAccounts({ app }: AppAccountsProps) {
  const { data, refetch } = useGetAppAccountsQuery({
    variables: { app: app.uuid }
  });

  const accounts = data?.getAppAccounts || [];

  return (
    <TableAutoHeight>
      {(maxHeight)=>(
        <TableContainer sx={{ maxHeight}}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  Name
                </TableCell>
                <TableCell width="150" sx={{ textAlign: 'center' }}>
                  Remove Account
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                accounts.map(account=>
                  <TableRow key={account.uuid}>          
                    <TableCell>
                      { account.name }
                    </TableCell>
                    <TableCell sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                      <RemoveAccountAppButton 
                        onRemove={()=>refetch()}
                        account={account} app={app}><IconButton><Delete /></IconButton></RemoveAccountAppButton>
                    </TableCell>
                  </TableRow>
                )
              }        
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </TableAutoHeight>
    
  )
}

type AppConfigProps = {
  app: AppFields_AdminFragment;
}

function AppConfig({ app }: AppConfigProps) {
  return (
    <div>
      <div>
        <h4 style={{ marginBottom: 5 }}>Launch Target:</h4>
        <div style={{ paddingLeft: 10 }}>{app.config.launch.target || 'none' }</div>
      </div>
      {
        app.config.launch.target === 'dialog' &&
        <div>
          <h4 style={{ marginBottom: 5 }}>Dialog:</h4>
          <div style={{ paddingLeft: 10 }}>
            <div>Height: { app.config.launch?.dialog?.height }</div>
            <div>Width: { app.config.launch?.dialog?.width }</div>
          </div>
        </div>
      }
    </div>
  )
}