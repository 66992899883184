import { useState, useEffect } from 'react';

import {
  useUpdateAppMutation,
  UpdateAppValues,
  AppFields_AdminFragment
} from '../../../__generated__/graphql';

import { 
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Switch,
  FormControlLabel,
  FormControl,
  ButtonProps,
  
} from '@mui/material'
import { LoadingButton } from '@mui/lab';
import lodash from 'lodash';
type DeepPartial<T> = T extends object ? {
  [P in keyof T]?: DeepPartial<T[P]>;
} : T;


type Props = {
  app: AppFields_AdminFragment;
  onDone?: (app: AppFields_AdminFragment)=>void;
} & ButtonProps
export default function UpdateAppButton({ app, onDone, ...props }: Props) {
  const getDefaultValues = (): UpdateAppValues => {
    return {
      name: app.name,
      handle: app.handle,
      service: {
        name: app.service.name,
        path: app.service.path
      },
      config: {
        launch: {
          target: app.config.launch.target,
          dialog: app.config.launch.dialog ? {
            height: app.config.launch.dialog.height,
            width: app.config.launch.dialog.width
          } : undefined
        }
      }
    }
  }

  const [openDialog, setOpenDialog] = useState(false);
  const [state, setState] = useState<UpdateAppValues>(getDefaultValues());
  const [updateApp, { loading }] = useUpdateAppMutation();

  const updateState = (values: DeepPartial<UpdateAppValues>) => {
    setState(lodash.merge({}, state, values));
  }

  useEffect(()=>{
    setState(getDefaultValues());
  }, [openDialog])


  const handleUpdateApp = async () => {
    const values = Object.assign(state);
    

    const { data, errors } = await updateApp({
      variables: { app: app.uuid, values }
    })

    if(errors) throw errors;
    if(!data?.updateApp) {
      throw new Error('could not update app')
    }
    setOpenDialog(false);
    if(onDone) onDone(data.updateApp);
  }

  const openInDialog = state.config?.launch?.target === 'dialog';

  return (<>
    <Button variant="contained" onClick={()=>setOpenDialog(true)} {...props}>Update App</Button>
    <Dialog open={openDialog} onClose={()=>setOpenDialog(false)}>
      <DialogContent>
        <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
          <TextField required value={state.name} onChange={(e)=>updateState({ name: e.target.value })} label="App Name" InputLabelProps={{ shrink: true }} />
          <TextField required value={state.handle} onChange={(e)=>updateState({ handle: e.target.value })} label="App Handle" InputLabelProps={{ shrink: true }} />
          <TextField required value={state.service?.name || ''} onChange={(e)=>updateState({ service: { name: e.target.value }})} label="Service Name" InputLabelProps={{ shrink: true }} />
          <TextField required value={state.service?.path || ''} onChange={(e)=>updateState({ service: { path: e.target.value }})} label="Service Path" InputLabelProps={{ shrink: true }} />
          
          <FormControl >
            <FormControlLabel 
              control={<Switch value={openInDialog} onChange={()=>updateState({ config: { launch: { target: openInDialog ? 'new_window' : 'dialog' }}})} />}
              label={"Open in " + (openInDialog ? 'dialog' : 'new window') }
            />
          </FormControl>

          <TextField disabled={!openInDialog} type="number" value={state.config?.launch?.dialog?.height || ''} onChange={(e)=>updateState({ config: { launch: { dialog: { height: parseInt(e.target.value) } }}})} label="Dialog Height" InputLabelProps={{ shrink: true }} />
          <TextField disabled={!openInDialog} type="number" value={state.config?.launch?.dialog?.width || ''} onChange={(e)=>updateState({ config: { launch: { dialog: { width: parseInt(e.target.value) } }}})} label="Dialog Width" InputLabelProps={{ shrink: true }} />
                    
        </div>
      </DialogContent>
      <DialogActions>
        <LoadingButton fullWidth variant="contained" loading={loading} onClick={handleUpdateApp}>Update App</LoadingButton>
      </DialogActions>
    </Dialog>
  </>)
}